var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: "javascript:void(0);",
        "data-bs-toggle": "popover",
        title: "Verfügbare Sonderangebote",
        "data-bs-trigger": "click",
        "data-bs-placement": "top",
        "data-bs-html": "true",
        "data-bs-content": _vm.getOfferText,
        tabindex: "0"
      }
    },
    [
      _c("img", {
        staticClass: "offer-img",
        attrs: {
          src:
            "https://r.v-office.com/preview/v2085/1705565353000/img/offers2.svg",
          width: "40",
          height: "40",
          "aria-label": "Angebote anzeigen"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }